// 岗位管理
<template>
    <div class="jobs">
        <div class="screening">
            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="筛选框" name="1">
                    <div class="screening-out">
                        <el-form :inline="true" :model="searchFrom" class="demo-form-inline">
                            <el-row>
                                <el-col :span="22">
                                    <el-form-item label="岗位名称">
                                        <el-input v-model="searchFrom.name" placeholder="请输入需要查询的岗位名称" size="mini"></el-input>
                                    </el-form-item>
                                    <!-- <el-form-item label="所属部门">
                                        <el-input v-model="searchFrom.name" placeholder="请输入需要查询的部门名称" size="mini"></el-input>
                                    </el-form-item> -->
                                    <!-- <el-form-item label="创建时间">
                                        <el-date-picker
                                            v-model="valueTime"
                                            type="datetimerange"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            clearable
                                            value-format="YYYY-MM-DD HH:mm:ss"
                                            size="mini"
                                            :default-time="defaultTime">
                                        </el-date-picker>
                                    </el-form-item> -->
                                    <el-form-item label="状态：" prop="">
                                        <el-select
                                        v-model="statusName"
                                        placeholder="请选择"
                                        style="width: 100%"
                                        clearable
                                        size="mini"
                                        >
                                        <el-option
                                            v-for="(item, index) in ['启用', '禁用']"
                                            :key="index"
                                            :label="item"
                                            :value="index"
                                        >
                                        </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2">
                                    <!-- <el-form-item> -->
                                        <div class="title-buttom">
                                            <el-button class="cancel" size="mini" @click="reset" round>重置</el-button>
                                            <el-button type="primary" size="mini" class="confirmAdd" @click="getDataList(true)" round>查询</el-button>
                                        </div>
                                    <!-- </el-form-item> -->
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <el-row>
            <el-col :span="12">
                <div class="title-name">
                    岗位列表
                </div>
            </el-col>
            <el-col :span="12">
                <div class="title-buttom">
                    <el-tooltip content="新增岗位" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-plus" @click="addData" circle></el-button>
                    </el-tooltip>
                    <el-tooltip content="刷新列表" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-refresh-right" @click="reset" circle></el-button>
                    </el-tooltip>
                    <!-- <el-button class="deletetable" size="mini" icon="el-icon-delete" @click="deleteData" round>删除</el-button> -->
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div>
                    <el-table
                    v-loading="loading"
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :max-height="tableHeight"
                    :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
                    @selection-change="handleSelectionChange">
                        <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
                        <el-table-column
                        label="岗位名称">
                        <template #default="scope">{{ scope.row.name == null?'-': scope.row.name}}</template>
                        </el-table-column>
                        <el-table-column
                        label="所属部门">
                        <template #default="scope">{{ scope.row.department == null?'-': scope.row.department}}</template>
                        </el-table-column>
                        <el-table-column
                        label="创建时间">
                        <template #default="scope">{{ scope.row.createTime == null?'-': scope.row.createTime}}</template>
                        </el-table-column>
                        <!-- <el-table-column
                        min-width="200" 
                        :show-overflow-tooltip="true"
                        label="备注">
                        <template #default="scope">{{ scope.row.remark == null?'-': scope.row.remark}}</template>
                        </el-table-column> -->
                        <el-table-column
                        label="启用/禁用" align="center">
                        <!-- <template #default="scope">
                            <el-popconfirm
                            confirmButtonText="确定"
                            cancelButtonText="取消"
                            icon="el-icon-info"
                            iconColor="red"
                            :title="scope.row.status == 0?'确定禁用这个职位吗？':'确定启用这个职位吗？'"
                            @confirm="control(scope.row.id,scope.row.status)"
                            @cancel="cancelEvent">
                                <template #reference>
                                    <el-button :class="scope.row.status == 0?'deletetable':'editor'" size="mini" :icon="scope.row.status == 0?'el-icon-close':'el-icon-check'" round></el-button>
                                </template>
                            </el-popconfirm>
                        </template> -->
                        <template #default="scope">
                            <a-switch 
                                size="small" 
                                v-model:checked="scope.row.status" 
                                :checkedValue="0"
                                :unCheckedValue="1"
                                @click="onSwitch(scope.row, scope.row.status)" 
                            />
                            <!-- <div style="height:25px;" @click="onSwitch(scope.row, scope.row.status)">
                                <el-switch
                                style="display: block"
                                v-model="scope.row.status"
                                :active-value = '0'
                                :inactive-value = '1'
                                active-color="#637DFF"
                                inactive-color="#ff6e3e">
                                </el-switch>
                            </div> -->
                        </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template #default="scope">
                                <el-tooltip content="编辑岗位" placement="top" effect="light">
                                    <el-button class="editor" size="mini" icon="el-icon-edit" @click="modify(scope.row.id)" round></el-button>
                                </el-tooltip>
                                <el-tooltip content="删除岗位" placement="top" effect="light">
                                        <el-button class="deletetable" size="mini" icon="el-icon-delete" @click="confirmEvent(scope.row.id)" round></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <div class="pager">
            <div>
               <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                background
                :page-sizes="[10, 20, 30, 40]"
                :page-size="searchFrom.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="popup-view">
            <el-form label-position="top" label-width="100px" :model="upform" :rules="rules" ref="upform">
                <el-dialog
                :title="popupTitle == 0?'新增岗位':'修改岗位'"
                v-model="centerDialogVisible"
                width="50%"
                @close='closeDialog'>
                <!-- 图标展示 -->
                <div class="popup-view">
                    <el-dialog
                        width="30%"
                        title="内弹窗"
                        v-model="innerVisible"
                        append-to-body>
                        <template #footer>
                            <el-form-item>
                                <el-button class="cancel" plain @click="innerVisible = false" round>取消</el-button>
                                <el-button class="confirmAdd" round>确认</el-button>
                            </el-form-item>
                        </template>
                    </el-dialog>
                </div>
                <div class="center-body">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="岗位名称：" prop="name">
                                <el-input v-model="upform.name"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="所属部门："  prop="departmentId">
                                <div class="elCascader">
                                    <el-cascader
                                    v-model="value"
                                    :options="treeList"
                                    @change="handleChange"
                                    :props="{checkStrictly: true,value:'id',label:'name',children: 'children'}">
                                </el-cascader>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="岗位备注：">
                                <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                    :autosize="{ minRows: 4, maxRows: 6}"
                                v-model="upform.remark"
                                maxlength="30"
                                show-word-limit
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <template #footer>
                        <el-form-item>
                            <el-button class="cancel" plain @click="resetForm('upform')" round>取消</el-button>
                            <el-button class="confirmAdd" @click="submitForm('upform')" round>{{ popupTitle == 0 ? '新增' : '修改' }}</el-button>
                        </el-form-item>
                </template>
                </el-dialog>
            </el-form>
        </div>
    </div>
</template>

<script>
import station from '@/api/system/station'
import department from '@/api/system/department'
import { IconFont } from "@/utils/iconfont"
import { getParent } from "@/public/js/tool"
export default {
    components: {
        IconFont,
    },
    data () {
        return {
            tableHeight:'',
            searchFrom:{
                page:1,
                pageSize:10,
                name:'',
                status:'',
                createTimeBegin:'',
                createTimeEnd:'',
            },//搜索值
            statusName:0,
            currentPage: 1,//分页
            total:0,
            multipleSelection: [],//选中集合
            treeList:[],//部门列表
            value:'',//所属部门
            valueTime:'',//选中时间段
            defaultTime: [
                new Date(2000, 1, 1, 12, 0, 0),
                new Date(2000, 2, 1, 8, 0, 0)
            ], //默认时间 '12:00:00', '08:00:00'
            activeNames: [],//折叠面板
            tableData: [],//数据列表
            loading: true,// loding 状态启用
            popupTitle:0,//弹窗类别
            centerDialogVisible: false,//弹窗启用
            innerVisible: false,//图标弹窗启用
            upform: {//岗位信息
                name: '',//岗位名称
                departmentId:'',//所属部门
                remark: '',//岗位备注
            },
            rules: {
                name: [
                    { required: true, message: '请输入岗位名称', trigger: 'blur' },
                    { min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur' }
                ],
                departmentId: [
                    { required: true, message: '请选择所属部门', trigger: 'blur' },
                ],
            }
        }
    },
    created() {
        this.tableHeight = this.$store.state.tableHeight
    },
    watch: {

    },
    mounted() {
        this.getDataList(true)
        this.getDepartmentTree()
    },
    methods: {
        // 重置
        reset() {
            this.valueTime = []
            this.statusName = 0
            this.searchFrom = {
                page: this.currentPage,
                pageSize: 10,  
            }
            this.getDataList(true)
        },
        // 获取部门树
        getDepartmentTree(){
            department.getDepartmentTree({}).then((res) => {
                this.treeList = res.data
            })
        },
        // 获取岗位列表
        getDataList(type) {
            this.loading = type
            this.searchFrom.createTimeBegin = this.valueTime[0]
            this.searchFrom.createTimeEnd = this.valueTime[1]
            // console.log(this.statusName)
            // this.statusName == '启用'?this.searchFrom.status = '0':this.searchFrom.status = '1'
            this.searchFrom.status = this.statusName
            station.getStation({
                ...this.searchFrom
            }).then((res) => {
                this.tableData = res.data.records
                this.total = res.data.total
                this.loading = false
            })
        },
        // 分页
        handleSizeChange(val) {
            this.searchFrom.pageSize = val
            this.getDataList(true)
        },
        handleCurrentChange(val) {
            this.searchFrom.page = val
            this.currentPage = val
            this.getDataList(true)
        },
        // 折叠面板
        handleChange(val) {
            console.log(val);
        },
        // 多选
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 批量删除
        deleteData() {
            console.log(this.multipleSelection)
        },
        // 新增
        addData(){
            this.popupTitle = 0
            this.centerDialogVisible = true
        },
        // 控制状态
        onSwitch(row, status){
            station.getStationEdit({
                id:row.id,
                status: status == 0 ? 0 : 1
            }).then((res) => {
                this.$notify({
                    title: '成功',
                    message: status == 0 ? '该岗位启用成功！' : '该岗位禁用成功！',
                    type: 'success'
                });
                this.getDataList(false)
                this.centerDialogVisible = false
            })
        },
        // 修改
        modify(id) {
            // 获取当前行详情
            station.getStationDetails({
                id:id
            }).then((res) => {
                this.upform = res.data
                let newPid = []
                let newPath = getParent(this.treeList, res.data.departmentId)[0].path.split(",")
                newPath.forEach((item,i) => {
                    if(item != ''){
                        newPid.push(item)
                    }
                });
                newPid.push(res.data.departmentId)
                this.value = newPid
                // this.value.splice(0,1)
                this.popupTitle = 1
                this.centerDialogVisible = true
            })
        },
        // 删除确认
        confirmEvent(id) {
            this.$confirm('将删除该岗位, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                station.getStationDelete({
                    id:id
                }).then((res) => {
                    this.$notify({
                        title: '成功',
                        message: '岗位删除成功！',
                        type: 'success'
                    });
                    this.getDataList(true)
                })
            }).catch(() => {
                this.$notify.info({
                    title: '通知',
                    message: '已取消删除!'
                });
            });
        },
        // 取消
        cancelEvent() {
            console.log("cancel!");
        },
        // 表单提交
        submitForm(formName) {
            this.upform.departmentId = this.value[this.value.length-1]
            this.$refs[formName].validate((valid) => {
            if (valid) {
                if( this.popupTitle == 0 ){
                    station.getStationAdd({
                        ...this.upform
                    }).then((res) => {
                        this.$notify({
                            title: '成功',
                            message: '岗位添加成功！',
                            type: 'success'
                        });
                        this.getDataList(true)
                        this.centerDialogVisible = false
                    })
                }else{
                    station.getStationEdit({
                        ...this.upform
                    }).then((res) => {
                        this.$notify({
                            title: '成功',
                            message: '岗位修改成功！',
                            type: 'success'
                        });
                        this.getDataList(true)
                        this.centerDialogVisible = false
                    })
                }
            } else {
                // console.log('error submit!!');
                return false;
            }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.value = []
            this.centerDialogVisible = false
        },
        closeDialog() {
            this.$refs.upform.resetFields()
            this.value = []
            this.centerDialogVisible = false
        },
    }
}
</script>

<style lang="scss" scoped>
.pager {
    display: flex;
    justify-content: flex-end;
}
.jobs{
    height: 100%;
    overflow-y: overlay;
    padding-right: 18px;
    box-sizing: content-box;
    .title-name{
        font-size: .9375rem;
        font-weight: 600;
        color: #303133;
    }
    .top-first{
        .search-box{
            width: 18.75rem;
        }
    }
}
.screening{
    margin-bottom: .9375rem;
}
.el-row {
    margin-bottom: .9375rem;
    &:last-child {
        margin-bottom: 0;
    }
}
.space-tile{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.editor{
    color: #0170ED;
    background: #E3F0FF;
}
.center-body{
    margin: 0 1.875rem;
}
.deletetable{
    color: #FF845B;
    background: #FFE4DB;
}
.deletetable-other{
    margin-right: 1.25rem;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.screening-out{
    margin: 0 .9375rem;
}
.showIcon{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    overflow-y: overlay;
    height: 28.125rem;
    .out-icon{
        border-radius: .5rem;
        .icon-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            justify-content: center;
            width:  4rem;
            height: 3rem;
        }
        .icon-name{
            text-align: center;
            width: 4rem;
            height: 2.5rem;
            line-height: 2.5rem;
            
        }
    }
    
}
.out-icon:hover{
    background: #E3F0FF;
    color: #0170ED;
}
</style>

<style lang="scss">
.jobs .el-collapse-item__content {
    padding-bottom: 0 !important;
}
// .jobs .el-button {
//     // border-color: #FFFFFF !important;
// }
</style>